<template>

  <div class="page-content">
    <div class="container-fluid">

      <Breadcrumb
          :pgTitle="$t('nav.mediaSettings')"
          :pgIcon="'bx bx-flag'"
          :refs="refs"
          :pgCurrent="pgCurrent"
          :addNew="false">
      </Breadcrumb>

      <div class="row">

        <Loading
            v-if="pgLoading">
        </Loading>

        <div v-if="!pgLoading" class="col-8">

          <FormElements
              :label="$t('view.media_settings_info')"
              :labelTitle="$t('view.title')"
              :hasTitle=true
              :title_ar="row.title_ar"
              :title_en="row.title_en"
              v-on:formChange="handleFormChange">
          </FormElements>

        </div>

        <div v-if="!pgLoading" class="col-4">

          <CardLecturer
              v-if="auth.role == 'root'"
              :lecturer_id="row.lecturer_id"
              v-on:lecturerChange="handleLecturerChange">
          </CardLecturer>

          <CardInput
              :label="$t('view.video_id')"
              :input="row.link"
              :type="'url'"
              v-on:inputChange="handleLinkChange">
          </CardInput>

          <CardStatus
              :status="row.status"
              v-on:statusChange="handleStatusChange">
          </CardStatus>

        </div>
      </div>


      <Buttons
          v-if="!pgLoading"
          :btnLoading="btnLoading"
          :btnCurrent="btnCurrent"

          v-on:submitClicked="handelSubmit"
          v-on:cancelClicked="handleCancel">
      </Buttons>

    </div>
  </div>

</template>

<script>
import iziToast from 'izitoast';

export default {
  name: 'EditOrNew',
  components: {
    Breadcrumb: () => import('@/components/Breadcrumb.vue'),
    Loading: () => import('@/components/Loading.vue'),
    FormElements: () => import('@/components/FormElements.vue'),
    CardLecturer: () => import('@/components/CardLecturer.vue'),
    CardInput: () => import('@/components/CardInput.vue'),
    CardStatus: () => import('@/components/CardStatus.vue'),
    Buttons: () => import('@/components/Buttons.vue'),
  },
  data() {
    return {
      // auth
      auth: {
        role: '',
        access_token: '',
      },

      // row
      row: {
        title_ar: '',
        title_en: '',
        link: 0,
        status: 1,
        lecturer_id: '',
      },

      msgCurrent: (this.$route.params.id) ? 'Updated' : 'Created',
      btnCurrent: (this.$route.params.id) ? 'Update' : 'Create',
      methodType: (this.$route.params.id) ? 'PUT' : 'POST',
      pgLoading: (this.$route.params.id) ? true : false,
      pgCurrent: (this.$route.params.id) ? 'Edit' : 'Add New',
      btnLoading: false,

      refs: 'mediaSettings',
    }
  },
  mounted() {
  },
  created() {
    // AccessToken & Role
    if (localStorage.getItem('access_token')) {
      this.auth.access_token = localStorage.getItem('access_token');
    }
    if (localStorage.getItem('role')) {
      this.auth.role = localStorage.getItem('role');
    }

    if (this.$route.params.id) {
      this.fetchRow();
    }
  },
  methods: {

    // fetch Row
    fetchRow() {
      this.pgLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };
      const options = {
        url: window.baseURL + '/' + this.refs + '/' + this.$route.params.id,
        method: 'GET',
        data: {}
      }
      this.axios(options)
          .then(res => {
            this.pgLoading = false;
            this.row.title_ar = res.data.row.ar.title;
            this.row.title_en = res.data.row.en.title;
            this.row.link = res.data.row.link;
            this.row.status = res.data.row.status;
            this.row.lecturer_id = res.data.row.lecturer_id;
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                name: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }

          })
          .finally(() => {
          });
    },


    // handle edit Or New submit
    handelSubmit() {
      this.btnLoading = true;
      this.axios.defaults.headers.common = {
        'X-Requested-With': 'XMLHttpRequest', // security to prevent CSRF attacks
        'Authorization': `Bearer ` + this.auth.access_token,
      };

      const config = {headers: {'Content-Type': 'multipart/form-data'}};
      const options = {
        url: (this.$route.params.id) ? window.baseURL + '/' + this.refs + '/' + this.$route.params.id : window.baseURL + '/' + this.refs,
        method: this.methodType,
        data: {
          ar: {
            title: this.row.title_ar,
          },
          en: {
            title: this.row.title_en,
          },
          lecturer_id: this.row.lecturer_id,
          link: this.row.link,
          status: this.row.status
        }
      }
      this.axios(options, config)
          .then(() => {
            this.btnLoading = false;
            iziToast.success({
              icon: 'bx bx-wink-smile',
              title: '',
              message: (this.btnCurrent == 'Update') ? 'تم التعديل بنجاح' : 'تم الإضافة بنجاح'
            });

            this.$router.push({name: this.refs});
          })
          .catch(err => {

            // 403 Forbidden
            if (err.response && err.response.status == 401) {
              this.clearLocalStorage();
              this.$router.push({name: 'login'});
            } else if (err.response && err.response.status == 403) {
              this.$router.push({name: 'forbidden'});
            } else {
              this.btnLoading = false;
              iziToast.warning({
                icon: 'bx bx-angry',
                title: '',
                message: (err.response) ? err.response.data.message : '' + err
              });
            }
          })
          .finally(() => {
          })

    },


    // clear Local Storage
    clearLocalStorage() {
      localStorage.removeItem('access_token');
      localStorage.removeItem('avatar');
      localStorage.removeItem('username');
      localStorage.removeItem('encrypt_id');
      localStorage.removeItem('role');
    },


    // Cancel
    handleCancel() {
      if (confirm('هل انت متأكد؟')) {
        this.$router.push({name: this.refs});
      }
    },
    // handel Changes from child components
    handleFormChange(event) {
      this.row.title_ar = event.title_ar;
      this.row.title_en = event.title_en;
    },

    handleLecturerChange(event) {
      this.row.lecturer_id = event.input;
    },

    handleLinkChange(event) {
      this.row.link = event.input;
    },

    handleStatusChange(event) {
      this.row.status = event.status;
    },

  },
}
</script>
